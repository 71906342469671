export default {
  getCookie: function (cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  },
  setCookie: function (cname, cvalue, exdays) {
    let expires = 'expires=Thu, 01 Jan 1970 00:00:01 GMT'
    if (exdays) {
      var d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      expires = 'expires=' + d.toUTCString()
    }
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/; domain=' + this.getDomainFromCookie(cname)
  },
  unsetCookie: function (cname) {
    this.setCookie(cname, '', false)
  },
  getUserCookie: function () {
    return this.checkLogin() ? JSON.parse(atob(this.getCookie('user'))) : false
  },
  checkLogin: function () {
    return (this.getCookie('user').length > 0)
  },
  getDomainFromCookie: function () {
    return this.checkLogin() ? (this.getUserCookie()).domain : ''
  },
}
