import axios from 'axios'
import Vue from 'vue'
import router from '@/router'

Vue.prototype.$http = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
})

Vue.prototype.$http.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('authorized')
      router.push('/pages/login')
    } else {
      return Promise.reject(error)
    }
  },
)
