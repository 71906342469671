const state = {
  notificationSnackbar: {
    text: '',
    color: 'success',
    trigger: true,
  },
  loading: {
    status: '',
    title: 'success',
    trigger: true,
  },
  isLoggedIn: false,
  firstLogin: false,
  firstVisit: true,
  conditionsDialogTrigger: true,
  navigationDrawer: {
    trigger: false,
  },
  isCalendarVisible: false,
  printerError: null,
}
const getters = {
  notification: state => {
    return state.notificationSnackbar
  },
  loading: state => {
    return state.loading
  },
  firstLogin: state => {
    return state.firstLogin
  },
  conditionsDialogTrigger: state => {
    return state.conditionsDialogTrigger
  },
  isLoggedIn: state => {
    return state.isLoggedIn
  },
  isCalendarVisible: state => {
    return state.isCalendarVisible
  },
  printerError: state => {
    return state.printerError
  },
}

const actions = {}

const mutations = {
  firstLogin (state, payload) {
    state.firstLogin = payload
  },
  loader (state, payload) {
    Object.keys(payload).forEach(key => {
      state.loading[key] = payload[key]
    })
    state.loading.trigger = !state.loading.trigger
  },
  firstVisit (state, payload) {
    state.firstVisit = payload
  },
  isLoggedIn (state, payload) {
    state.isLoggedIn = payload
  },
  notify (state, payload) {
    Object.keys(payload).forEach(key => {
      state.notificationSnackbar[key] = payload[key]
    })
    state.notificationSnackbar.trigger = !state.notificationSnackbar.trigger
  },
  openNavigation (state) {
    state.navigationDrawer.trigger = !state.navigationDrawer.trigger
  },
  isCalendarVisible (state, payload) {
    state.isCalendarVisible = payload
  },
  printerError (state, payload) {
    state.printerError = payload
  },
}

export const viewstate = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
