import Vue from 'vue'
import Vuex from 'vuex'

import { user } from './modules/user_module'
import { orders } from './modules/order_module'
import { viewstate } from './modules/viewstate_module'
import { application } from './modules/application_module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: user,
    order: orders,
    application: application,
    viewstate: viewstate,
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
  },
  actions: {

  },
})
