import Vue from 'vue'

Vue.filter('time', value => {
  const unix = Date.parse(value)
  const date = new Date(unix)

  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()

  return (
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0')
  )
})
