import Vue from 'vue'
import Router from 'vue-router'
import CookieHelper from './helpers/CookieHelper'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          meta: { authRequired: false },
          component: () => import('@/views/pages/Lock'),
        },
        {
          name: 'Login',
          path: 'login',
          meta: { authRequired: false },
          component: () => import('@/views/pages/Login'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          meta: { authRequired: true },
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'Orders',
          path: 'pages/orders',
          meta: { authRequired: true },
          component: () => import('@/views/dashboard/Order'),
        },
        {
          name: 'OrderOverview',
          path: 'pages/orders/:id',
          meta: { authRequired: true },
          component: () => import('@/views/pages/OrderOverview'),
        },
        {
          name: 'Instellingen',
          path: 'pages/settings',
          meta: { authRequired: true },
          component: () => import('@/views/pages/Settings'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          meta: { authRequired: false },
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

// validate admin & authentication each route change
router.beforeEach((to, from, next) => {
  store.commit('user/setAuth', CookieHelper.checkLogin())

  if (to.meta.authRequired && !store.getters['user/authenticated'] && to.name !== 'Login') next('/pages/login')
  else next()
})

export default router
