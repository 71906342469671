import Vue from 'vue'

const state = {
  configuration: null,
}

const actions = {
  getConfiguration (context, payload) {
    return Vue.prototype.$http.get('/api/v1/application/config')
  },
  putConfiguration (context, payload) {
    return Vue.prototype.$http.put('/api/v1/application/config', payload)
  },
}

const mutations = {
  configuration (state, payload) {
    state.configuration = payload
  },
}

const getters = {
  configuration: state => {
    return state.configuration
  },
}

export const application = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
