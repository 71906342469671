import Vue from 'vue'

Vue.filter('date', value => {
  const unix = Date.parse(value)
  const date = new Date(unix)

  const day = date.getUTCDate()
  const month = date.getUTCMonth()
  const year = date.getUTCFullYear()

  return (
    day.toString().padStart(2, '0') +
    '-' +
    (month + 1).toString().padStart(2, '0') +
    '-' +
    year.toString()
  )
})
