import Vue from 'vue'

Vue.filter('datetime', value => {
  const timezoneOffset = new Date(value).getTimezoneOffset()
  const time = new Date(value).getTime() + ((new Date().getTimezoneOffset() - timezoneOffset) * 60000)
  const date = new Date(time)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return (
    day.toString().padStart(2, '0') +
    '-' +
    month.toString().padStart(2, '0') +
    '-' +
    year.toString() +
    ' ' +
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0')
  )
})
