import Vue from 'vue'

Vue.filter('currency', value => {
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  })

  return formatter.format(value)
})
