<template>
  <v-app>
    <router-view />
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      class="text-center"
      top
    >
      <h3 class="font-weight-light">
        {{ snackbar.text }}
      </h3>
      <v-btn
        icon
        @click="snackbar.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="loading.show"
      width="400"
    >
      <v-card>
        <div class="pa-10">
          <h3 class="text-center mb-10">
            {{ loading.title }}
          </h3>
          <v-row class="justify-center">
            <v-progress-circular
              v-if="loading.status === 'loading'"
              :size="150"
              :width="6"
              color="primary"
              indeterminate
            />
            <v-slide-y-transition>
              <v-icon
                v-if="loading.status === 'succeeded'"
                size="150"
                color="primary"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-if="loading.status === 'failed'"
                size="150"
                color="error"
              >
                mdi-alert-circle-outline
              </v-icon>
            </v-slide-y-transition>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    data: () => ({
      snackbar: {
        show: false,
        text: '',
        color: 'error',
      },
      loading: {
        show: false,
        title: 'Bezig met opslaan...',
        status: 'loading',
      },
    }),
    computed: {
      snackbarTrigger () {
        return this.$store.state.viewstate.notificationSnackbar.trigger
      },
      loadingTrigger () {
        return this.$store.state.viewstate.loading.trigger
      },
    },
    watch: {
      snackbarTrigger: function () {
        const globalSnackbar = this.$store.state.viewstate.notificationSnackbar
        this.snackbar = {
          show: true,
          text: globalSnackbar.text,
          color: globalSnackbar.color,
        }
      },
      beforeCreate () {
        this.$vuetify.lang.current = process.env.VUE_APP_I18N_LOCALE || 'en'
      },
      loadingTrigger: function () {
        const loading = this.$store.state.viewstate.loading
        this.loading = {
          show: true,
          title: loading.title,
          status: loading.status,
        }
        if (loading.status === 'succeeded' || loading.status === 'failed') {
          setTimeout(() => (this.loading.show = false), 2000)
        }
      },
    },
  }
</script>
