import Vue from 'vue'

const state = {
  authenticated: false,
  user: null,
}

const actions = {
  login (context, payload) {
    return Vue.prototype.$http.post('api/v1/login', payload)
  },
}

const mutations = {
  user (state, payload) {
    Object.keys(payload).forEach(key => {
      state.user[key] = payload[key]
    })
  },
  setAuth (state, payload) {
    state.authenticated = payload
  },
}

const getters = {
  user: state => {
    return state.user
  },
  authenticated: state => {
    return state.authenticated
  },
}

export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
