import Vue from 'vue'

const state = {
  orders: [],
  orders_today: [],
}

const actions = {
  getOrdersV1 (context, payload) {
    let url = '/api/v1/orders/'
    if (payload !== undefined) {
      const orderId = payload.id
      url = '/api/v1/orders/' + orderId
    }
    return Vue.prototype.$http.get(url)
  },
  getOrdersTodayV1 (context, payload) {
    return Vue.prototype.$http.get('/api/v1/orders/today')
  },
  putDeliveryDateV1 (context, payload) {
    return Vue.prototype.$http.put('/api/v1/orders/' + payload.id + '/delivery-date', payload.body)
  },
  putOrderItemV1 (context, payload) {
    return Vue.prototype.$http.put('/api/v1/order-items/' + payload.id, payload.body)
  },
}

const mutations = {
  orders (state, payload) {
    state.orders = payload
  },
  ordersToday (state, payload) {
    state.orders_today = payload
  },
}

const getters = {
  orders: state => {
    return state.orders
  },
  ordersToday: state => {
    return state.orders_today
  },
  totalOrders: state => {
    return state.orders_today.length.toString()
  },
  totalRevenue: state => {
    var total = 0
    if (state.orders_today.length > 0) {
      total = state.orders_today.map(item => item.total).reduce((prev, next) => parseFloat(prev) + parseFloat(next))
    }
    return total.toString()
  },
  totalNewOrders: state => {
    var total = 0
    state.orders_today.filter(function (object) {
      if (object.application_status === 'new') {
        total++
      }
    })
    return total.toString()
  },
  ordersInProduction: state => {
    var total = 0
    state.orders_today.filter(function (object) {
      if (object.application_status === 'in_production') {
        total++
      }
    })
    return total.toString()
  },
}

export const orders = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
